import React, { useContext, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ErrorPage from "../../layouts/ErrorPage/ErrorPage";
import ScrollToTop from "../../layouts/ScrollToTop/ScrollToTop";
import ViewContent from "../../pages/ViewContent/ViewContent";
const MaizeLanding = React.lazy(() =>
  import("../../pages/Maize/MaizeLanding/MaizeLanding")
);

function EnglishRoutes() {
  return (
    <div>
      <Router>
        <Suspense
          fallback={
            <div style={{ textAlign: "center", marginTop: "40%" }}>
              Loading App...Plase Wait.{}
            </div>
          }
        >
          <ScrollToTop>
            <Switch>
            <Route exact path="/" component={MaizeLanding} />
            <Route exact path="/viewcontent" component={ViewContent} />

              <Route path="*" component={ErrorPage} />
            </Switch>
          </ScrollToTop>
        </Suspense>
      </Router>
    </div>
  );
}

export default EnglishRoutes;
