import React, { useContext, useEffect } from "react";
import AppLogo from "../../layouts/AppLogo/AppLogo";
import AppNav from "../../layouts/AppNav/AppNav";
import Headerimage from "../../layouts/HeaderImage/Headerimage";
import OrgLogos from "../../layouts/OrgLogos/OrgLogos";
import Preheader from "../../layouts/PreHeader/Preheader";
import queryString from "query-string";
import { ValueChainsContext } from "../../contexts/ValueChainsContext/ValueChainsContext";
import AppContainer from "../../layouts/AppContainer/AppContainer";
import AppLeftSideContainer from "../../layouts/AppLeftSideContainer/AppLeftSideContainer";
import AppRightSideContainer from "../../layouts/AppRightSideContainer/AppRightSideContainer";
import AppContentCard from "../../layouts/AppContentCard/AppContentCard";
import { AppContext } from "../../contexts/AppContext/AppContext";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

function ViewContent({ location }) {
  const { appContentUrl, appVCUrl, appName } = useContext(AppContext);
  const qValue = queryString.parse(location.search);
  const { vcid, vcName } = qValue;
  const {
    specificvaluechains,
    setValueChainDetail,
    setContentsFromVC,
    getValueChainsDetail,
    getContentFromVC,
    valueChainDetail,
    contentsFromVC,
    isLoading,
    isLoadingContent,
    vcisLoading,
  } = useContext(ValueChainsContext);
  useEffect(() => {
    console.log("vc vhanges");

    console.log({ vcid });

    getValueChainsDetail(parseInt(vcid));
    getContentFromVC(parseInt(vcid));
    return () => {
      // cleanup
      setValueChainDetail([]);
      setContentsFromVC(undefined);
    };
  }, [vcid]);
  return (
    <div>
      <Preheader></Preheader>
      <OrgLogos></OrgLogos>
      <AppLogo appName={valueChainDetail.name} to="/"></AppLogo>
      <AppNav to="/" appName={valueChainDetail.name}></AppNav>
      <Headerimage
        pageHeader={valueChainDetail.name}
        img={valueChainDetail.cover_image}
      ></Headerimage>
      {/* {
    JSON.stringify(valueChainDetail)
} */}
      <AppContainer>
        <AppLeftSideContainer>
          {vcisLoading && <LoadingScreen />}

          <>
            {valueChainDetail.intro !== undefined && (
              <div
                id="introDiv"
                dangerouslySetInnerHTML={{
                  __html: `${valueChainDetail.intro}`,
                }}
              />
            )}
          </>

          {/* <AppContentCard title="title 1" content="content 1" message="message 1" target={`#index1`} index={`index1`}>

      </AppContentCard> */}
        </AppLeftSideContainer>
        <AppRightSideContainer></AppRightSideContainer>
      </AppContainer>
    </div>
  );
}

export default ViewContent;
