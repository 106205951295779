import React, { useEffect, useContext, useState, createContext } from "react";

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [appLanguage, setappLanguage] = useState("1");
  const [appLanguageShortName, setappLanguageShortName] = useState("ENG");

  const [welcomeMsg, setWelcomeMsg] = useState(null);
  const [ValueChainAppName, setValueChainAppName] = useState("");
  const [isloading, setIsLoading] = useState(null);
  // http://127.0.0.1:8000/
  //197.156.129.158:29
  const AppIp = `http://127.0.0.1:8000/`;
  const OnlineAppIp = `/`;

  const [AppUrl, setApiAppUrl] = useState(`${AppIp}api/laikipiacontent/`);

  const appName="Fall Armyworm"

  const [appLanguageUrl, setAppLanguageUrl] = useState(
    `${AppIp}api/laikipiacontent/language/`
  );
  const [englishContentUrl, setEnglishContentUrl] = useState(
    `${AppIp}api/laikipiacontent/englishcontent/`
  );
  const [swahiliContentUrl, setSwahiliContentUrl] = useState(
    `${AppIp}api/laikipiacontent/swahilicontent/`
  );
  const [englishVCUrl, setEnglishVCUrl] = useState(
    `http://127.0.0.1:8000/api/laikipiacontent/valuechain/`
  );
  const [swahiliVCUrl, setSwahiliVCUrl] = useState(
    `${AppIp}api/laikipiacontent/swahilivaluechain/`
  );
  const [appContentUrl, setAppUrl] = useState(englishContentUrl);
  const [appVCUrl, setAppVCUrl] = useState("http://127.0.0.1:8000/api/laikipiacontent/valuechain/");

  const saveOfflineLanguage = (val) => {
    localStorage.setItem("appLang", val);
    // setappLanguage(val);
    // console.log("Saved lang offline, isEnglish for app is ", appLanguage);
  };

  const saveOfflineAppLanguage = (key, val) => {
    localStorage.setItem(key, val);
    // setappLanguage(val);
    // console.log("Saved app lang");
  };

  const saveOfflineSnackbarMsg = () => {
    localStorage.setItem("welcomeMsg", false);
    setWelcomeMsg(false);
  };

  const changeAppLang = (lang, langUrl, vcUrl, shortName) => {
    setappLanguage(lang);
    // saveOfflineLanguage(lang);

    // console.log(typeof(lang));
    setAppUrl(`${AppIp}api/laikipiacontent/${langUrl}`);
    setAppVCUrl(`${AppIp}api/laikipiacontent/${vcUrl}`);
    setappLanguageShortName(shortName);

    saveOfflineAppLanguage("appLang", lang);
    saveOfflineAppLanguage(
      "appContentLangUrl",
      `${AppIp}api/laikipiacontent/${langUrl}`
    );
    saveOfflineAppLanguage("appVcUrl", `${AppIp}api/laikipiacontent/${vcUrl}`);
    saveOfflineAppLanguage("appLangShortName", shortName);

    return 0;
    if (lang === "1") {
      setAppUrl(englishContentUrl);
      setAppVCUrl(englishVCUrl);
    } else {
      setAppUrl(swahiliContentUrl);
      setAppVCUrl(swahiliVCUrl);
    }
  };

  const getOfflinewelcome = () => {
    setTimeout(() => {
      if (localStorage.hasOwnProperty("welcomeMsg") === true) {
        let welcome = localStorage.getItem("welcomeMsg");
        setWelcomeMsg(welcome);
      } else {
        saveOfflineSnackbarMsg(false);
      }
    }, 20);
  };

  const getOfflineLanguage = () => {
    setTimeout(() => {
      if (localStorage.hasOwnProperty("appLang") === true) {
        let appLang = localStorage.getItem("appLang");

        // console.log("appLang", appLang);
        setappLanguage(appLang);

        // if (appLang === "1") {
        //   setAppUrl(englishContentUrl);
        //   setAppVCUrl(englishVCUrl);
        // } else if (appLang === "2") {
        //   setAppUrl(swahiliContentUrl);
        //   setAppVCUrl(swahiliVCUrl);
        // }

        // setAppUrl(swahiliContentUrl)
        // setAppVCUrl(swahiliVCUrl)
      } else {
        // saveOfflineLanguage("1");
        setappLanguage("1");
        saveOfflineAppLanguage("appLang", "1");

        // setAppUrl(englishContentUrl)
        // setAppVCUrl(englishVCUrl)

        setIsLoading(false);
        // console.log("appLangeuage", appLanguage);
      }
    }, 20);
  };

  const getOfflineVCUrl = () => {
    setTimeout(() => {
      if (localStorage.hasOwnProperty("appVcUrl") === true) {
        let appVCURl = localStorage.getItem("appVcUrl");

        // console.log("appVcUrl", appVCURl);
        setAppVCUrl(appVCURl);

        // setAppUrl(swahiliContentUrl)
        // setAppVCUrl(swahiliVCUrl)
      } else {
        //        setAppVCUrl(`${AppIp}api/laikipiacontent/${appVCUrl}`);
        saveOfflineAppLanguage("appVcUrl", appVCUrl);

        // setAppUrl(englishContentUrl)
        // setAppVCUrl(englishVCUrl)

        setIsLoading(false);
      }
    }, 20);
  };

  const getofflineappContentLangUrl = () => {
    return 0
    setTimeout(() => {
      if (localStorage.hasOwnProperty("appContentLangUrl") === true) {
        let appContentLangUrl = localStorage.getItem("appContentLangUrl");

        // console.log("appContentLangUrl", appContentLangUrl);
        setAppUrl(appContentLangUrl);

        // setAppUrl(swahiliContentUrl)
        // setAppVCUrl(swahiliVCUrl)
      } else {
        setAppUrl(`${englishContentUrl}`);
        saveOfflineAppLanguage("appContentLangUrl", englishContentUrl);

        // setAppUrl(englishContentUrl)
        // setAppVCUrl(englishVCUrl)

        setIsLoading(false);
      }
    }, 20);
  };

  const getOfflineLangShortName = () => {
    setTimeout(() => {
      if (localStorage.hasOwnProperty("appLangShortName") === true) {
        let appShortname = localStorage.getItem("appLangShortName");

        // console.log("appLangShortName", appShortname);
        setappLanguageShortName(appShortname);

        // setAppUrl(swahiliContentUrl)
        // setAppVCUrl(swahiliVCUrl)
      } else {
        setappLanguageShortName(appLanguageShortName);
        saveOfflineAppLanguage("appLangShortName", appLanguageShortName);

        // setAppUrl(englishContentUrl)
        // setAppVCUrl(englishVCUrl)

        setIsLoading(false);
      }
    }, 20);
  };

  useEffect(() => {
    getOfflineLanguage();
    getOfflineVCUrl();
    getOfflineLangShortName();
    getofflineappContentLangUrl();
    // getOfflinewelcome();
  }, []);
  return (
    <AppContext.Provider
      value={{
        appLanguage,
        setappLanguage,
        saveOfflineLanguage,
        isloading,
        AppUrl,
        welcomeMsg,
        setValueChainAppName,
        englishContentUrl,
        swahiliContentUrl,
        appContentUrl,
        changeAppLang,
        appContentUrl,
        appVCUrl,
        appLanguageUrl,
        appLanguageShortName,appName
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
