import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../contexts/AppContext/AppContext'
import { ValueChainsContext } from '../../contexts/ValueChainsContext/ValueChainsContext'

function AppNavEnglishLinks(props) {
  const {valuechains}=useContext(ValueChainsContext)
  const {appName}=useContext(AppContext)

  const fisrtvaluechain=valuechains.slice(0, 5)
    return (
       
    <nav id="myNav" class="navbar navbar-expand-lg navbar-light">
       <div className="container">
        <Link
          style={{ color: "white", background: "transparent", display: "none" }}
          class="navbar-brand"
          to={props.to}
        >
          {appName}
        </Link>
        <a href="#" class="imaginaryLink"></a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active"></li>

          
            {valuechains.slice(0, 3).map((vc)=>{
              return (
                <li class="nav-item" key={vc.id}>
                <Link class="nav-link" to={`viewcontent?vcid=${vc.id}&vcName=${vc.name}`}>
                  {vc.name}
                </Link>
              </li>
              )
            })}



{
  valuechains.length > 3 && 
  <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
               More
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              {valuechains.slice(3,valuechains.length).map((vc)=>{
              return (
                <li class="nav-item" key={vc.id}>
                <Link class="nav-link" to={`viewcontent?vcid=${vc.id}&vcName=${vc.name}`}>
                  {vc.name}
                </Link>
              </li>
              )
            })}

                
              </div>
            </li>

}

          
          </ul>
        </div>
        </div>
      </nav> 
       
    )
}

export default AppNavEnglishLinks
