import React from "react";

function AppContentCard(props) {
  const { message, content, title, index, target } = props;
  return (
    <div class="card">
      {props.children}
      <div class="card-header">{title}</div>
      <div class="card-body">
        {message}

        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <div
              id={index}
              class="accordion-collapse collapse"
              aria-labelledby={`flush${index}`}
              data-bs-parent="#accordionFlushExample"
            >
              <div
                class="accordion-body"
                style={{ paddingTop: 20, paddingLeft: 10 }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                    `${content}`
                  }}
                />
              </div>
            </div>
            <br></br>
            <h2 class="accordion-header" id={`flush${index}`}>
              <button
                class="btn btn-primary"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={target}
                aria-expanded="false"
                aria-controls={index}
              >
                Read more
              </button>
            </h2>
          </div>
          {/* <div class="accordion-item">
            <div
              id={index}
              class="accordion-collapse collapse"
              aria-labelledby={index}
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">{content}</div>
            </div>
            <h2 class="accordion-header" id={index}>
              <button
                class="btn btn-primary"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={target}
                aria-expanded="false"
                aria-controls={index}
              >
                Read more
              </button>
            </h2>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AppContentCard;
