import React, { useEffect, useContext, useState, createContext } from "react";
import { VC_URL } from "../../constants/appurls.js";
import { AxiosGetService } from "../../Services/AxiosServices/AxiosServices";
import { AppContext } from "../AppContext/AppContext";

export const ValueChainsContext = createContext();

const ValueChainsContextProvider = (props) => {
  const [valuechains, setValueChains] = useState([]);
  const [valueChainDetail, setValueChainDetail] = useState([]);
  const [vcisLoading, setvcIsLoading] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingContent, setIsLoadingContent] = useState(null);



  const {
    setValueChainAppName,
    englishContentUrl,
    swahiliContentUrl,
    appContentUrl,
    AppUrl,
    appVCUrl,
    appLanguageUrl,
  } = useContext(AppContext);
  const [specificvaluechains, setSpecificValueChains] = useState({
    id: 1,
    name: "Bees",
  });
  const [contentsFromVC, setContentsFromVC] = useState([]);
  const [languages, setLanguages] = useState([]);

  const getValueChains = async () => {
    // console.log("getting vc")

    // return 0
    setvcIsLoading(true);

    setTimeout(async () => {
      try {
        const getRes = await AxiosGetService(VC_URL);
        const getResData = await getRes;
        setvcIsLoading(true);

        //   console.log();
        setValueChains(getResData.data);
        setvcIsLoading(false);
      } catch (e) {
        // setValueChains([]);
        setvcIsLoading(false);
      }
    }, 2000);
  };

 

  const getValueChainsDetail = async (id) => {
    // console.log("getting vc detail")
    setValueChainDetail({
      "name":"..."
    });


    // return 0
    try {
    setvcIsLoading(true);

      setIsLoading(true);
      const getRes = await AxiosGetService(`${VC_URL}${id}/`);
      const getResData = await getRes;

      // console.log(getResData.data);
      
        setValueChainDetail(getResData.data);
        setIsLoading(false);
        setvcIsLoading(false);

     
    } catch (e) {
      console.log({e})
      setIsLoading(false);
      setvcIsLoading(false);

      setValueChainDetail([]);
    }
  };

  const getContentFromVC = async (id, url = `${appContentUrl}`) => {
    // console.log("getting vc")

    return 0;
    try {
      setIsLoadingContent(true);
      const getRes = await AxiosGetService(`${url}${id}`);
      const getResData = await getRes;
      // console.log(getResData.data);
        setContentsFromVC(getResData.data);
        setIsLoadingContent(false);
     
    } catch (e) {
      console.log(e)
      console.log(url)
      setContentsFromVC([]);

      setIsLoadingContent(false);
    }
  };
  useEffect(() => {
    getValueChains();
    // getContentFromVC()
    // setValueChainAppName(specificvaluechains.name)
  }, [appVCUrl, appContentUrl]);

  useEffect(() => {
    // getAppLanguages();
  }, []);
  return (
    <ValueChainsContext.Provider
      value={{
        setValueChainDetail,
        setContentsFromVC,
        valuechains,
        specificvaluechains,
        getValueChains,
        contentsFromVC,
        getValueChainsDetail,
        getContentFromVC,
        valueChainDetail,
        languages,
        isLoading,vcisLoading,isLoadingContent
      }}
    >
      {props.children}
    </ValueChainsContext.Provider>
  );
};

export default ValueChainsContextProvider;
