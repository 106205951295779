// http://67.207.91.52/

// let API="http://127.0.0.1:8000/api/laikipiacontent/"
let API="http://67.207.91.52:8001/api/laikipiacontent/"


let VC_URL=`${API}valuechain/`


export {
    API,VC_URL
}