import React from 'react'
import image1 from '../../images/kalrologo90-64-2.gif'
import image2 from '../../images/laikipia_emblem.png'
import image3 from '../../images//ACWICT.png'
import { Link } from 'react-router-dom'

function OrgLogos() {
    return (
        <div id='appLogos'>
            
       <Link to='/'>
          <img src={image1}></img>

          {/* <img src={image2} id='appLogoimg2'></img> */}
          {/* <img src={image3}></img> */}

            
          </Link>
           
        </div>
    )
}

export default OrgLogos
