import React from "react";
import { useContext } from "react";
import { Link } from 'react-router-dom'
import { ValueChainsContext } from "../../contexts/ValueChainsContext/ValueChainsContext";

function AppRightSideContainer(props) {
  const {valuechains}=useContext(ValueChainsContext)

  return <div class="col-sm-4"> {props.children}
   {valuechains.map((vc)=>{
              return (
                
                <Link class="nav-link" to={`viewcontent?vcid=${vc.id}&vcName=${vc.name}`}>
                  {vc.name}
                </Link>
              
              )
            })}
  
  </div>;
}

export default AppRightSideContainer;
