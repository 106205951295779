import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../contexts/AppContext/AppContext'
function AppLogo(props) {
    const {appName}=useContext(AppContext)
    return (
        <div id="appLogoDiv" style={{}}>
            <Link id="appLogo" style={{}} to={props.to}>{props.appName}</Link>
        </div>
    )
}

export default AppLogo
